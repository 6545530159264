import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { COLORS } from '../../constants/constants';

const BarChart = ({ data = [], heigth = "500px", width = "100%", xAxisStart, xAxisEnd }) => {
    // Accumulate volumes based on timestamps
    const volumeMap = data.reduce((acc, entry) => {
        const date = entry.date instanceof Date ? entry.date : new Date(entry.date); // Use `date` field

        if (isNaN(date.getTime())) {
            console.warn(`Invalid date: ${entry.date}`);
            return acc;
        }

        const twoHoursInMilliseconds = 2 * 60 * 60 * 1000;
        const adjustedTimeStamp = date.getTime() + twoHoursInMilliseconds;

        acc[adjustedTimeStamp] = (acc[adjustedTimeStamp] || 0) + entry.volume;
        return acc;
    }, {});

    // Transform accumulated data into chart series format
    const chartData = Object.entries(volumeMap).map(([timeStamp, volume]) => ({
        x: Number(timeStamp),
        y: volume,
    }));

    const options = {
        chart: {
            type: 'column',
            backgroundColor: COLORS.LIGHTWHITE,
            plotBorderWidth: 1,
            plotBorderColor: '#ccc',
        },
        title: {
            text: 'Volume Over Time',
        },
        xAxis: [
            {
                type: 'datetime',
                title: {
                    text: 'Date and Time',
                },
                tickInterval: 3600 * 1000, // 1-hour intervals
                labels: {
                    format: '{value:%H:%M}', // Show time (hours and minutes)
                },
                min: xAxisStart ? new Date(xAxisStart).getTime() : undefined,
                max: xAxisEnd ? new Date(xAxisEnd).getTime() : undefined,
            },
            {
                linkedTo: 0, // Link this axis to the primary x-axis
                type: 'datetime',
                opposite: true, // Place it above the primary axis
                tickInterval: 24 * 3600 * 1000, // 1-day intervals
                labels: {
                    format: '{value:%Y-%m-%d}', // Show day (year-month-day)
                    style: {
                        fontSize: '15px', // Adjust size for distinction
                    },
                },
            },
        ],
        yAxis: {
            title: {
                text: 'Volume',
            },
            gridLineWidth: 0,
        },
        plotOptions: {
            column: {
                pointPadding: 0.1,
                groupPadding: 0.1,
                borderWidth: 0,
                color: COLORS.THIRD,
                borderRadius: 3,
            },
        },
        series: [
            {
                name: 'Volume',
                data: chartData,
            },
        ],
        tooltip: {
            xDateFormat: '%Y-%m-%d %H:%M:%S',
            pointFormat: 'Volume: <b>{point.y}</b>',
        },
    };

    return (
        <HighchartsReact
            containerProps={{ style: { height: heigth, width } }}
            highcharts={Highcharts}
            options={options}
        />
    );
};

export default BarChart;
