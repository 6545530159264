import styled from 'styled-components';
// import { COLORS } from "../../constants/constants";
import background from '../../images/background_kouga_dam.jpeg'

export const DashboardContainer = styled.div`
    // background-image: url(${background});
    // background-size: cover;
    // background-repeat: no-repeat;
    // background-position: center;
    // height: 100vh;
    // width: 100%;
`;

export const DashboardSubContainer = styled.div`
`

export const DashboardContent = styled.aside.withConfig({
    shouldForwardProp: (prop) => prop !== 'sidebar'
  })`
    // width: ${({ sidebar }) => (sidebar ? '88.56%' : '95.85%')};
    height: 100%;
    margin-left: ${({ sidebar }) => (sidebar ? '220px' : '80px')};
    margin-top: 10px;
    transition: 0.3s all ease-in-out;

    @media screen and (max-width: 768px) {
        margin-left: 0px;
        // width: 100%;
        transition: 0.3s all ease-in-out;
    }
`
