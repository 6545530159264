import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { COLORS } from '../../constants/constants';

const BarChartLines = ({ data = [], height = "400px", width = "100%" }) => {
    // Map of line names to volumes
    const volumeMap = data.reduce((acc, entry) => {
        if (entry.line && entry.volume) {
            acc[entry.line] = (acc[entry.line] || 0) + entry.volume;
        }
        return acc;
    }, {});

    // Prepare the chart data
    const lineNames = Object.keys(volumeMap); // Extract line names for x-axis
    const volumes = Object.values(volumeMap); // Extract corresponding volumes for y-axis

    const options = {
        chart: {
            type: 'column',
            backgroundColor: COLORS.LIGHTWHITE,
            plotBorderWidth: 1,
            plotBorderColor: '#ccc',
        },
        title: {
            text: 'Volume by Lines',
        },
        xAxis: {
            categories: lineNames, // Use line names as x-axis categories
            title: {
                text: 'Line Names',
            },
            labels: {
                style: {
                    fontSize: '14px',
                },
            },
        },
        yAxis: {
            title: {
                text: 'Volume',
            },
            gridLineWidth: 0,
        },
        plotOptions: {
            column: {
                pointPadding: 0.1,
                groupPadding: 0.1,
                borderWidth: 0,
                color: COLORS.THIRD,
                borderRadius: 3,
            },
        },
        series: [
            {
                name: 'Volume',
                data: volumes, // Use volumes for bar heights
            },
        ],
        tooltip: {
            pointFormat: 'Volume: <b>{point.y}</b>',
        },
    };

    return (
        <HighchartsReact
            containerProps={{ style: { height, width } }}
            highcharts={Highcharts}
            options={options}
        />
    );
};

export default BarChartLines;
