import React, { useEffect, useState } from 'react';
import Navbar from '../../components/NavBar';
import { Error, InputContainer, LabelUsage, Metric, ScheduledTimeContainer, UpdateButton, UsageUpdateContainer, UsageUpdateItemSpan, UsageUpdateSectionContainer, UsageUpdateSubContainer, UsageUpdateTitle, VolumeInput } from './UsageUpdateElements';
import { useLocation } from 'react-router';
import Select from 'react-select';
import Counter from '../../components/Counter';
import { ToastContainer, toast } from 'react-toastify';
import { CONNECTIONS, customDropdownStyles, customDropdownTimeStyles, usageSectionOptions, usageTimeOptions } from '../../constants/constants';


const UsageUpdate = () => {

    const location = useLocation();
    const { id, credentialId, type, amount, size, volume, section, startTime, endTime, createdDate } = location.state || {};

    const [selectedUsageAmount, setSelectedUsageAmount] = useState(amount);
    const [selectedUsageSize, setSelectedUsageSize] = useState(size);
    const [selectedUsageVolume, setSelectedUsageVolume] = useState(volume);
    const [selectedUsageSection, setSelectedUsageSection] = useState(section);
    const [selectedUsageStartTime, setSelectedUsageStartTime] = useState(null);
    const [selectedUsageEndTime, setSelectedUsageEndTime] = useState(endTime);
    const [endTimeEnabled, setEndTimeEnabled] = useState(true);
    const [endTimeOptions, setEndTimeOptions] = useState([]);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        handleSelectStartTime({ value: getHourValue(startTime), label: getHour(startTime) });
        setSelectedUsageEndTime({ value: getHourValue(endTime), label: getHour(endTime) })

        usageSectionOptions.forEach(sectionCheck => {
            if (sectionCheck.value === section) {
                setSelectedUsageSection(sectionCheck)
            }
        });
    }, []);

    const handleSelectStartTime = (time) => {
        if (time != null) {
            setEndTimeEnabled(false);
            const start = time.value;
            const options = [];
            const end = parseInt(start) + 6;

            for (let i = parseInt(start) + 1; i <= end; i++) {
                const label = i < 10 ? `0${i}:00` : `${i}:00`;
                options.push({ value: `${i}`, label: label });
            }
            setEndTimeOptions(options);
        } else {
            setEndTimeEnabled(true);
        }
        setSelectedUsageStartTime(time);
    }

    const handleSetUsageVolume = (event) => {
        setSelectedUsageVolume(event.target.value);
    };

    const getHour = (datetime) => {
        const date = new Date(datetime);
        date.setHours(date.getUTCHours() + 2);
        const timeString = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
        return timeString;
    };

    const getHourValue = (datetime) => {
        const date = new Date(datetime);
        date.setHours(date.getUTCHours() + 2);
        const timeString = date.toLocaleTimeString([], { hour: '2-digit', hour12: false });
        return parseInt(timeString);
    };

    const showSuccessToast = () => {
        toast.success("Update was successful!", {
            position: "top-right",
            autoClose: 3000, // Auto close after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
        });
    };

    const handleSubmitDamUpdate = async (e) => {
        e.preventDefault();

        if ((id !== "" && id !== null) && 
            (type !== "" && type !== null) &&
            (selectedUsageAmount !== "" && selectedUsageAmount !== null) && 
            (selectedUsageVolume !== "" && selectedUsageVolume !== null && selectedUsageVolume != 0) && 
            (selectedUsageSection !== "" && selectedUsageSection !== null) && 
            (selectedUsageStartTime !== "" && selectedUsageStartTime !== null) && 
            (selectedUsageEndTime !== "" && selectedUsageEndTime !== null)) {

            const startDate = new Date();
            startDate.setHours(selectedUsageStartTime.value, 0, 0, 0);
            const startDateTimeStamp = startDate.toISOString();

            const endDate = new Date();
            endDate.setHours(selectedUsageEndTime.value, 0, 0, 0);
            const endDateTimeStamp = endDate.toISOString();

            try {

                var url = `${CONNECTIONS.SERVICE_URL_UA}/api/${id}`;

                var responseBody = {
                    Type: type,
                    Amount: selectedUsageAmount,
                    Size: 0,
                    Volume: selectedUsageVolume,
                    Section: selectedUsageSection.value,
                    StartTime:startDateTimeStamp,
                    EndTime: endDateTimeStamp,
                }
    
                console.log(responseBody)

                const response = await fetch(url, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(responseBody),
                });
    
                if (!response.ok) {
                    throw new Error('Submit Failed');
                } else {
                    showSuccessToast();
                }
    
                setError("");
    
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }

            
        } else {
            //set error
            setError("Please make sure everything is entered.");
        }

    }

    const handleSubmitPivotUpdate = async (e) => {
        e.preventDefault();

        if ((id !== "" && id !== null) && 
            (type !== "" && type !== null) &&
            (selectedUsageAmount !== "" && selectedUsageAmount !== null) && 
            (selectedUsageSize !== "" && selectedUsageSize !== null && selectedUsageSize != 0) && 
            (selectedUsageSection !== "" && selectedUsageSection !== null) && 
            (selectedUsageStartTime !== "" && selectedUsageStartTime !== null) && 
            (selectedUsageEndTime !== "" && selectedUsageEndTime !== null)) {

            const startDate = new Date();
            startDate.setHours(selectedUsageStartTime.value, 0, 0, 0);
            const startDateTimeStamp = startDate.toISOString();

            const endDate = new Date();
            endDate.setHours(selectedUsageEndTime.value, 0, 0, 0);
            const endDateTimeStamp = endDate.toISOString();

            try {

                var url = `${CONNECTIONS.SERVICE_URL_UA}/api/${id}`;

                var responseBody = {
                    Type: type,
                    Amount: selectedUsageAmount,
                    Size: selectedUsageSize,
                    Volume: 0,
                    Section: selectedUsageSection.value,
                    StartTime:startDateTimeStamp,
                    EndTime: endDateTimeStamp,
                }

                console.log(responseBody)
    
                const response = await fetch(url, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(responseBody),
                });
    
                if (!response.ok) {
                    throw new Error('Submit Failed');
                } else {
                    showSuccessToast();
                }
    
                const data = await response.json();
    
                setError("");
    
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        } else {
            //set error
            setError("Please make sure everything is entered.");
        }
    }


    return (
        <UsageUpdateContainer>
            <Navbar option={0}/>
            <>
                
                {type !== null && type === "dam" && (
                    <UsageUpdateSubContainer>
                        <UsageUpdateSectionContainer>
                            <UsageUpdateTitle>Type of Usage</UsageUpdateTitle>  
                            <UsageUpdateItemSpan>{type}</UsageUpdateItemSpan>
                            <UsageUpdateItemSpan>{getHour(startTime)}</UsageUpdateItemSpan>
                            <UsageUpdateItemSpan>{getHourValue(startTime)}</UsageUpdateItemSpan>
                        </UsageUpdateSectionContainer>
                        <UsageUpdateSectionContainer>
                            <UsageUpdateTitle>Amount of {type}s</UsageUpdateTitle>  
                            <Counter counterAmount={selectedUsageAmount} setCounterAmount={setSelectedUsageAmount}/>
                        </UsageUpdateSectionContainer>

                        <UsageUpdateSectionContainer>
                            <UsageUpdateTitle>Volume</UsageUpdateTitle> 
                            <InputContainer>
                                <VolumeInput
                                    id="numberInput"
                                    type="number"
                                    value={selectedUsageVolume}
                                    onChange={handleSetUsageVolume}
                                    min="0"
                                    max="1000000000000"
                                /> 
                                <Metric>
                                    m³
                                </Metric>
                            </InputContainer>
                            
                        </UsageUpdateSectionContainer>

                        <UsageUpdateSectionContainer>
                            <UsageUpdateTitle>Line</UsageUpdateTitle> 
                            <Select 
                                value={selectedUsageSection}
                                onChange={setSelectedUsageSection} 
                                options={usageSectionOptions} 
                                styles={customDropdownStyles} 
                            />
                        </UsageUpdateSectionContainer>

                        <UsageUpdateSectionContainer>
                            <UsageUpdateTitle>Scheduled Time</UsageUpdateTitle> 
                            <ScheduledTimeContainer>
                                <LabelUsage>From</LabelUsage>
                                <Select 
                                    value={selectedUsageStartTime}
                                    onChange={handleSelectStartTime} 
                                    options={usageTimeOptions} 
                                    styles={customDropdownTimeStyles} 
                                />
                                <LabelUsage>To</LabelUsage>
                                <Select 
                                    value={selectedUsageEndTime}
                                    onChange={setSelectedUsageEndTime} 
                                    options={endTimeOptions} 
                                    styles={customDropdownTimeStyles} 
                                    isDisabled={endTimeEnabled}
                                />
                            </ScheduledTimeContainer>

                        </UsageUpdateSectionContainer>
                        {error !== "" && error !== null && (
                            <Error>
                                {error}
                            </Error>
                        )}
                        <ToastContainer />
                        <UpdateButton onClick={handleSubmitDamUpdate}>
                            Update
                        </UpdateButton>
                    </UsageUpdateSubContainer>
                )}
                {type !== null && type === "pivot" && (
                    <UsageUpdateSubContainer>
                        <UsageUpdateSectionContainer>
                            <UsageUpdateTitle>Amount of {type}s</UsageUpdateTitle>  
                            <Counter counterAmount={selectedUsageAmount} setCounterAmount={setSelectedUsageAmount}/>
                        </UsageUpdateSectionContainer>
                        
                        <UsageUpdateSectionContainer>
                            <UsageUpdateTitle>Amount of Towers</UsageUpdateTitle>  
                            <Counter counterAmount={selectedUsageSize} setCounterAmount={setSelectedUsageSize}  counterMin={2} counterMax={7}/>
                        </UsageUpdateSectionContainer>
                        
                        <UsageUpdateSectionContainer>
                            <UsageUpdateTitle>Line</UsageUpdateTitle> 
                            <Select 
                                value={selectedUsageSection}
                                onChange={setSelectedUsageSection} 
                                options={usageSectionOptions} 
                                styles={customDropdownStyles} 
                            />
                        </UsageUpdateSectionContainer>
                        
                        <UsageUpdateSectionContainer>
                            <UsageUpdateTitle>Scheduled Time</UsageUpdateTitle> 
                            <ScheduledTimeContainer>
                                <LabelUsage>From</LabelUsage>
                                <Select 
                                    value={selectedUsageStartTime}
                                    onChange={handleSelectStartTime} 
                                    options={usageTimeOptions} 
                                    styles={customDropdownTimeStyles} 
                                />
                                <LabelUsage>To</LabelUsage>
                                <Select 
                                    value={selectedUsageEndTime}
                                    onChange={setSelectedUsageEndTime} 
                                    options={endTimeOptions} 
                                    styles={customDropdownTimeStyles} 
                                    isDisabled={endTimeEnabled}
                                />
                            </ScheduledTimeContainer>

                        </UsageUpdateSectionContainer>
                        {error !== "" && error !== null && (
                            <Error>
                                {error}
                            </Error>
                        )}
                        <ToastContainer />
                        <UpdateButton onClick={handleSubmitPivotUpdate}>
                            Update
                        </UpdateButton>
                        
                    </UsageUpdateSubContainer>
                )}

            </>
        </UsageUpdateContainer>
    )
};

export default UsageUpdate;
