import React, { useState } from 'react';
import Navbar from '../../components/NavBar';
import { DashboardContainer, DashboardContent, DashboardSubContainer, Img } from './DashboardElements';
import Sidebar from '../../components/Sidebar';
import Modal from '../../components/Modal';
import Usage from '../../components/Usage';
import Home from '../../components/Home';
import { useSelector } from 'react-redux';
import AllUsage from "../../components/AllUsage"
import Stats from "../../components/Stats"
import Users from "../../components/Users"
import Settings from "../../components/Settings"


const Dashboard = () => {
    const {id, token, email, name, surname, phone, type} = useSelector((state) => state.credentials);
    const [isOpen, setIsOpen] = useState(false);
    const [sidebar, setSideBar] = useState(true);
    const [pageOption, setPageOption] = useState(1);

    const toggle = () => {
        setIsOpen(!isOpen);
    }

    return (
        <DashboardContainer>
            <Modal isOpen={isOpen} toggle={toggle}  pageOption={pageOption} setPageOption={setPageOption}/>
            <Navbar toggle={toggle} option={1}/>
            <Sidebar sidebar={sidebar} setSideBar={setSideBar} pageOption={pageOption} setPageOption={setPageOption}/>
            <DashboardContent sidebar={sidebar}>
                {type === "usergamtooswater" && (
                    <DashboardSubContainer>
                        {pageOption === 1 && (
                            <Home setPageOption={setPageOption}/>
                        )}
                        {pageOption === 2 && (
                            <Usage pageOption={pageOption} setPageOption={setPageOption}/>
                        )}
                        {pageOption === 3 && (
                            <Settings/>
                        )}
                    </DashboardSubContainer>
                )}
                {type === "admingamtooswater" && (
                    <DashboardSubContainer>
                        {pageOption === 1 && (
                            <Home setPageOption={setPageOption}/>
                        )}
                        {/* {pageOption === 2 && (
                            <AllUsage/>
                        )} */}
                        {pageOption === 3 && (
                            <Stats/>
                        )}
                        {pageOption === 4 && (
                            <Users/>
                        )}
                        {pageOption === 5 && (
                            <Settings/>
                        )}
                    </DashboardSubContainer>
                )}
            </DashboardContent>
        </DashboardContainer>
    )
};

export default Dashboard;
