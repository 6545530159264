import React, { useState } from 'react';
import Navbar from '../../components/NavBar';
import {
    LoginContainer,
    IconContainer,
    BackIcon,
    FormContainer,
    Form,
    FormInput,
    FormLabel,
    FormButton,
    FormWrapper,
    FormTitle
} from './LoginElements';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { CONNECTIONS } from '../../constants/constants';
import { setCredentials } from '../../slices/credentialsSlice';

const Login = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const toggle = () => {
        setIsOpen(!isOpen);
    }

    const handleChange = (e) => {
        const { id, value } = e.target;
        if (id === 'email') {
            setEmail(value);
        } else if (id === 'password') {
            setPassword(value);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        setLoading(true);
        setError(null);

        try {

            var url = `${CONNECTIONS.SERVICE_URL_CA}/api/login`;

            var requestBody = {
                email: email,
                password: password
            };

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            });

            if (!response.ok) {
                throw new Error('Login failed');
            }

            const data = await response.json();
            dispatch(setCredentials({
                token: data.token,
                email: data.email,
                id: data.id,
                name: data.name,
                surname: data.surname,
                phone: data.phone,
                type: data.type,
            }));
            navigate('/dashboard');

        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <Navbar toggle={toggle} option={0}/>
            <LoginContainer>
                <FormContainer>
                    <FormWrapper>
                        <FormTitle>Login</FormTitle>
                        <Form onSubmit={handleSubmit}>
                            <FormLabel htmlFor='email'>Email</FormLabel>
                            <FormInput
                                type='email'
                                id='email'
                                value={email}
                                onChange={handleChange}
                                required
                            />
                            <FormLabel htmlFor='password'>Password</FormLabel>
                            <FormInput
                                type='password'
                                id='password'
                                value={password}
                                onChange={handleChange}
                                required
                            />
                            {error && <p style={{ color: 'red' }}>{error}</p>}
                            <FormButton type='submit' disabled={loading}>
                                {loading ? 'Logging in...' : 'Login'}
                            </FormButton>
                        </Form>
                    </FormWrapper>
                </FormContainer>
            </LoginContainer>
        </div>
    )
};

export default Login;
