import React, { useState, useEffect } from 'react';
import {LabelUsage, AddButton, AddButtonContainer, ScheduledTimeContainer, UsageContainer, UsageSectionContainer, UsageSubContainer, UsageTitle, SubmitButton, VolumeInput, InputContainer, Metric, Error, Success, UsageItemsContainer, UsageItemContainer, UsageItemSpan, UsageItemSubContainer, EditButtonContainer, EditButton, DeleteButtonContainer, DeleteButton, ButtonsContainer } from './UsageElements';
import { useSelector } from 'react-redux';
import GroupButtons from '../GroupButtons';
import {customDropdownStyles, customDropdownTimeStyles, groupButtonsUsage, usageSectionOptions, usageTimeOptions, usageTypeOptions } from '../../constants/constants';
import Select from 'react-select';
import Counter from '../Counter';
import { CONNECTIONS } from '../../constants/constants';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaTrashAlt } from "react-icons/fa";
import { useNavigate } from 'react-router';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField } from '@mui/material';

const Usage = () => {
    const navigate = useNavigate();

    const {name, surname, id} = useSelector((state) => state.credentials);
    const [usageOption, setUsageOption] = useState(0);
    const [selectedUsageType, setSelectedUsageType] = useState(null);
    const [selectedUsageAmount, setSelectedUsageAmount] = useState(1);
    const [selectedUsageSize, setSelectedUsageSize] = useState(2);
    const [selectedUsageVolume, setSelectedUsageVolume] = useState(0);
    const [selectedUsageSection, setSelectedUsageSection] = useState(null);
    const [selectedUsageStartTime, setSelectedUsageStartTime] = useState(null);
    const [selectedUsageEndTime, setSelectedUsageEndTime] = useState(null);
    const [endTimeEnabled, setEndTimeEnabled] = useState(true);
    const [endTimeOptions, setEndTimeOptions] = useState([]);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [usageItems, setUsageItems] = useState([]);
    const [updateItems, setUpdateItems] = useState(0);
    const [selectedDate, setSelectedDate] = useState(null);

    useEffect(() => {
        // This effect runs on component mount
        const fetchData = async () => {
            try {

                var url = `${CONNECTIONS.SERVICE_URL_UA}/api/all/current/${id}`;
    
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                const result = await response.json();
                setUsageItems(result);
    
                setError("");
    
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [updateItems]);

    const handleUpdate = () => {
        setUpdateItems(updateItems + 1)
    }

    const showSuccessToast = () => {
        toast.success("Post was successful!", {
            position: "top-right",
            autoClose: 3000, // Auto close after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
        });
    };

    const handleUsageType = (option) => {
        setSelectedUsageType(option);
    };

    const handleSelectStartTime = (time) => {
        if (time != null) {
            setEndTimeEnabled(false);
            const start = time.value;
            const options = [];
            const end = parseInt(start) + 6;

            for (let i = parseInt(start) + 1; i <= end; i++) {
                var modValue = i % 24
                const label = modValue < 10 ? `0${modValue}:00` : `${modValue}:00`;
                options.push({ value: `${modValue}`, label: label });
            }
            setEndTimeOptions(options);
        } else {
            setEndTimeEnabled(true);
        }
        setSelectedUsageStartTime(time);
    }

    const handleSetUsageVolume = (event) => {
        setSelectedUsageVolume(event.target.value);
    };

    const handleSubmitDam = async (e) => {
        e.preventDefault();

        if ((id !== "" && id !== null) && 
            (selectedUsageType !== "" && selectedUsageType !== null) &&
            (selectedUsageAmount !== "" && selectedUsageAmount !== null) && 
            (selectedUsageVolume !== "" && selectedUsageVolume !== null && selectedUsageVolume != 0) && 
            (selectedUsageSection !== "" && selectedUsageSection !== null) && 
            (selectedUsageStartTime !== "" && selectedUsageStartTime !== null) && 
            (selectedUsageEndTime !== "" && selectedUsageEndTime !== null) &&
            (selectedDate !== "" && selectedDate !== null)) {

            const startDate = new Date();
            startDate.setHours(selectedUsageStartTime.value, 0, 0, 0);
            const startDateTimeStamp = startDate.toISOString();

            const endDate = new Date();
            endDate.setHours(selectedUsageEndTime.value, 0, 0, 0);
            const endDateTimeStamp = endDate.toISOString();

            const newSelectedDate = new Date(selectedDate);
            newSelectedDate.setHours(newSelectedDate.getHours() + 5);
            const selectedDateStamp = newSelectedDate.toISOString();

            try {

                var url = `${CONNECTIONS.SERVICE_URL_UA}/api`;

                var responseBody = {
                    CredentialId: id,
                    Type: selectedUsageType.value,
                    Amount: selectedUsageAmount,
                    Size: 0,
                    Volume: selectedUsageVolume,
                    Section: selectedUsageSection.value,
                    Date: selectedDateStamp,
                    StartTime:startDateTimeStamp,
                    EndTime: endDateTimeStamp,
                }
    
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(responseBody),
                });
    
                if (!response.ok) {
                    throw new Error('Submit Failed');
                } else {
                    showSuccessToast();
                    handleUpdate();
                    setUsageOption(0);
                    handleClearUsage();
                }
    
                setError("");
    
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }

            
        } else {
            //set error
            setError("Please make sure everything is entered.");
        }

    }

    const handleClearUsage = () => {
        setSelectedUsageType(null);
        setSelectedUsageAmount(1);
        setSelectedUsageSize(2);
        setSelectedUsageVolume(0);
        setSelectedUsageSection(null);
        setSelectedUsageStartTime(null);
        setSelectedUsageEndTime(null);
    }

    const handleSubmitPivot = async (e) => {
        e.preventDefault();

        if ((id !== "" && id !== null) && 
            (selectedUsageType !== "" && selectedUsageType !== null) &&
            (selectedUsageAmount !== "" && selectedUsageAmount !== null) && 
            (selectedUsageSize !== "" && selectedUsageSize !== null && selectedUsageSize != 0 && selectedUsageSize >= 2 && selectedUsageSize <= 7) && 
            (selectedUsageSection !== "" && selectedUsageSection !== null) && 
            (selectedUsageStartTime !== "" && selectedUsageStartTime !== null) && 
            (selectedUsageEndTime !== "" && selectedUsageEndTime !== null) &&
            (selectedDate !== "" && selectedDate !== null)) {

            const startDate = new Date();
            startDate.setHours(selectedUsageStartTime.value, 0, 0, 0);
            const startDateTimeStamp = startDate.toISOString();

            const endDate = new Date();
            endDate.setHours(selectedUsageEndTime.value, 0, 0, 0);
            const endDateTimeStamp = endDate.toISOString();

            const newSelectedDate = new Date(selectedDate);
            newSelectedDate.setHours(newSelectedDate.getHours() + 5);
            const selectedDateStamp = newSelectedDate.toISOString();

            try {

                var url = `${CONNECTIONS.SERVICE_URL_UA}/api`;

                var responseBody = {
                    CredentialId: id,
                    Type: selectedUsageType.value,
                    Amount: selectedUsageAmount,
                    Size: selectedUsageSize,
                    Volume: 0,
                    Section: selectedUsageSection.value,
                    Date: selectedDateStamp,
                    StartTime:startDateTimeStamp,
                    EndTime: endDateTimeStamp,
                }
    
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(responseBody),
                });
    
                if (!response.ok) {
                    throw new Error('Submit Failed');
                } else {
                    showSuccessToast();
                    handleUpdate();
                    setUsageOption(0);
                    handleClearUsage();
                }
    
                const data = await response.json();
    
                setError("");
    
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        } else {
            //set error
            setError("Please make sure everything is entered.");
        }
    }

    const getHour = (datetime) => {
        const date = new Date(datetime);
        date.setHours(date.getUTCHours() + 2);
        const timeString = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
        return timeString;
    };

    const handleUsageEdit = (item) => {
        navigate("/usageupdate", {state: item})
    }

    const handleUsageDelete = async (item) => {
        try {

            var url = `https://${CONNECTIONS.SERVICE_URL_UA}/api/${item.id}`;

            const response = await fetch(url, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Delete Failed');
            }

            handleUpdate();
            setError("");

        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    }

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { day: 'numeric', month: 'short', year: 'numeric' };
        return date.toLocaleDateString('en-GB', options); // Use 'en-GB' for the desired format
    }

    return (
        <UsageContainer>
            <GroupButtons selectedOption={usageOption} setSelectedOption={setUsageOption} list={groupButtonsUsage}/>
            {usageOption === 0 && (
                <>
                {usageItems && usageItems.map((item, index) => (
                    <UsageItemsContainer key={index}>
                        {item.type === "dam" && (
                            <UsageItemContainer>
                                <UsageItemSubContainer>
                                    <UsageItemSpan>
                                        Type: {item.type}
                                    </UsageItemSpan>
                                    <UsageItemSpan>
                                        Line: {item.section}
                                    </UsageItemSpan>
                                    <UsageItemSpan>
                                        Amount: {item.amount}
                                    </UsageItemSpan>
                                    <UsageItemSpan>
                                        Volume: {item.volume} m³
                                    </UsageItemSpan>
                                    <UsageItemSpan>
                                        Date: {formatDate(item.date)}
                                    </UsageItemSpan>
                                    <UsageItemSpan>
                                        Start Time: {getHour(item.startTime)}
                                    </UsageItemSpan>
                                    <UsageItemSpan>
                                        End Time: {getHour(item.endTime)}
                                    </UsageItemSpan>
                                </UsageItemSubContainer>
                                <ButtonsContainer>
                                    <EditButtonContainer>
                                        <EditButton onClick={() => handleUsageEdit(item)}>
                                            Edit
                                        </EditButton>
                                    </EditButtonContainer>
                                    <DeleteButtonContainer>
                                        <DeleteButton onClick={() => handleUsageDelete(item)}>
                                            <FaTrashAlt />
                                        </DeleteButton>
                                    </DeleteButtonContainer>
                                </ButtonsContainer>
                            </UsageItemContainer>
                        )}
                        {item.type === "pivot" && (
                            <UsageItemContainer>
                                <UsageItemSubContainer>
                                    <UsageItemSpan>
                                        Type: {item.type}
                                    </UsageItemSpan>
                                    <UsageItemSpan>
                                        Line: {item.section}
                                    </UsageItemSpan>
                                    <UsageItemSpan>
                                        Amount: {item.amount}
                                    </UsageItemSpan>
                                    <UsageItemSpan>
                                        Towers: {item.size}
                                    </UsageItemSpan>
                                    <UsageItemSpan>
                                        Date: {formatDate(item.date)}
                                    </UsageItemSpan>
                                    <UsageItemSpan>
                                        Start Time: {getHour(item.startTime)}
                                    </UsageItemSpan>
                                    <UsageItemSpan>
                                        End Time: {getHour(item.endTime)}
                                    </UsageItemSpan>
                                </UsageItemSubContainer>
                                <ButtonsContainer>
                                    <EditButtonContainer>
                                        <EditButton onClick={() => handleUsageEdit(item)}>
                                            Edit
                                        </EditButton>
                                    </EditButtonContainer>
                                    <DeleteButtonContainer>
                                        <DeleteButton onClick={() => handleUsageDelete(item)}>
                                            <FaTrashAlt />
                                        </DeleteButton>
                                    </DeleteButtonContainer>
                                </ButtonsContainer>
                                
                            </UsageItemContainer>
                        )} 
                    </UsageItemsContainer>
                ))}
                </>
            )}
            {usageOption === 1 && (
                <>
                    <UsageSectionContainer>
                        <UsageTitle>Select Type</UsageTitle>
                        <Select 
                            value={selectedUsageType}
                            onChange={handleUsageType} 
                            options={usageTypeOptions} 
                            styles={customDropdownStyles} 
                        />
                    </UsageSectionContainer>
                    
                    {selectedUsageType !== null && selectedUsageType.value === "dam" && (
                        <UsageSubContainer>
                            <UsageSectionContainer>
                                <UsageTitle>Amount of {selectedUsageType.value}s</UsageTitle>  
                                <Counter counterAmount={selectedUsageAmount} setCounterAmount={setSelectedUsageAmount}/>
                            </UsageSectionContainer>

                            <UsageSectionContainer>
                                <UsageTitle>Volume</UsageTitle> 
                                <InputContainer>
                                    <VolumeInput
                                        id="numberInput"
                                        type="number"
                                        value={selectedUsageVolume}
                                        onChange={handleSetUsageVolume}
                                        min="0"
                                        max="1000000000000"
                                    /> 
                                    <Metric>
                                        m³
                                    </Metric>
                                </InputContainer>
                                
                            </UsageSectionContainer>

                            <UsageSectionContainer>
                                <UsageTitle>Line</UsageTitle> 
                                <Select 
                                    value={selectedUsageSection}
                                    onChange={setSelectedUsageSection} 
                                    options={usageSectionOptions} 
                                    styles={customDropdownStyles} 
                                />
                            </UsageSectionContainer>

                            <UsageSectionContainer>
                                <UsageTitle>Date</UsageTitle> 
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        value={selectedDate}
                                        onChange={(date) => setSelectedDate(date)}
                                    />
                                </LocalizationProvider>
                            </UsageSectionContainer>
                            

                            <UsageSectionContainer>
                                <UsageTitle>Scheduled Time</UsageTitle> 
                                <ScheduledTimeContainer>
                                    <LabelUsage>From</LabelUsage>
                                    <Select 
                                        value={selectedUsageStartTime}
                                        onChange={handleSelectStartTime} 
                                        options={usageTimeOptions} 
                                        styles={customDropdownTimeStyles} 
                                    />
                                    <LabelUsage>To</LabelUsage>
                                    <Select 
                                        value={selectedUsageEndTime}
                                        onChange={setSelectedUsageEndTime} 
                                        options={endTimeOptions} 
                                        styles={customDropdownTimeStyles} 
                                        isDisabled={endTimeEnabled}
                                    />
                                </ScheduledTimeContainer>

                            </UsageSectionContainer>
                            {error != "" && error != null && (
                                <Error>
                                    {error}
                                </Error>
                            )}
                            <ToastContainer />
                            <SubmitButton onClick={handleSubmitDam}>
                                Submit
                            </SubmitButton>
                        </UsageSubContainer>
                    )}
                    {selectedUsageType !== null && selectedUsageType.value === "pivot" && (
                        <UsageSubContainer>
                            <UsageSectionContainer>
                                <UsageTitle>Amount of {selectedUsageType.value}s</UsageTitle>  
                                <Counter counterAmount={selectedUsageAmount} setCounterAmount={setSelectedUsageAmount}/>
                            </UsageSectionContainer>
                            
                            <UsageSectionContainer>
                                <UsageTitle>Amount of Towers</UsageTitle>  
                                <Counter counterAmount={selectedUsageSize} setCounterAmount={setSelectedUsageSize} counterMin={2} counterMax={7}/>
                            </UsageSectionContainer>
                            
                            <UsageSectionContainer>
                                <UsageTitle>Line</UsageTitle> 
                                <Select 
                                    value={selectedUsageSection}
                                    onChange={setSelectedUsageSection} 
                                    options={usageSectionOptions} 
                                    styles={customDropdownStyles} 
                                />
                            </UsageSectionContainer>

                            <UsageSectionContainer>
                                <UsageTitle>Date</UsageTitle> 
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        value={selectedDate}
                                        onChange={(date) => setSelectedDate(date)}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </UsageSectionContainer>
                            
                            <UsageSectionContainer>
                                <UsageTitle>Scheduled Time</UsageTitle> 
                                <ScheduledTimeContainer>
                                    <LabelUsage>From</LabelUsage>
                                    <Select 
                                        value={selectedUsageStartTime}
                                        onChange={handleSelectStartTime} 
                                        options={usageTimeOptions} 
                                        styles={customDropdownTimeStyles} 
                                    />
                                    <LabelUsage>To</LabelUsage>
                                    <Select 
                                        value={selectedUsageEndTime}
                                        onChange={setSelectedUsageEndTime} 
                                        options={endTimeOptions} 
                                        styles={customDropdownTimeStyles} 
                                        isDisabled={endTimeEnabled}
                                    />
                                </ScheduledTimeContainer>

                            </UsageSectionContainer>
                            {error != "" && error != null && (
                                <Error>
                                    {error}
                                </Error>
                            )}
                            <ToastContainer />
                            <SubmitButton onClick={handleSubmitPivot}>
                                Submit
                            </SubmitButton>
                            
                        </UsageSubContainer>
                    )}

                </>
                

            )}
            
        </UsageContainer>
        
    )
};

export default Usage;